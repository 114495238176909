<template>
  <div
    class="camera-capture"
    :class="{
      'camera-capture--empty': !fieldData.value,
    }"
  >
    <div class="file-wrapper relative">
      <VueFilePond
        name="file"
        ref="pond"
        @updatefiles="updateFiles"
        v-bind="config"
        :files="myFiles"
        v-bind:server="imageUploadServer"
        :class="[{'error__highlight': error,'labelInput': isCameraOpen }]"
      />
      <div v-if="showOpenCamera && !previewForm" class="add-photo__controls left-5 mt-5">
        <p class="labelCamera">OR</p>
        <button
            v-if="!previewForm"
            class="add-photo__button cursor-pointe border border-gray-700 w-20 min-w-min px-3 py-1 bg-white hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center font-semibold whitespace-nowrap disabled:opacity-50"
            @click="toggleCamera"
        >{{ isCameraOpen ? 'Close camera' : 'Open camera' }}</button>
        <div v-show="isCameraLoading" class="camera-loading">
          <ul class="loader-circle">
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <camera-field
      ref="cameraField"
      :isOpen="isCameraOpen"
      :extension="extension"
      @input="addCapture"
    />
  </div>

</template>

<script>
import VueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginGetFile from 'filepond-plugin-get-file';
// import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import axios from "@/axios";
import store from "../../../store";

import cameraField from "./camera-field";

export default {
  name: 'camera-capture-field',
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    // is used to prview filled form before its submitting:
    previewForm:{
        type: Boolean,
        default: false
    },
  },
  components: {
    VueFilePond: VueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImagePreview,
      // FilePondPluginPdfPreview,
      FilePondPluginGetFile,
    ),
    cameraField,
  },
  data() {
    return {
      isCameraOpen: false,
      isCameraLoading: false,
      dynamicPlaceholder: '',
      config: {
        'label-idle': this.dynamicPlaceholder,
        'credits': 'false',
        'allow-multiple': false,
        'accepted-file-types': "image/jpeg, image/png",
      },
      case_id:null, 
      token:null,
      imageUploadServer: {
        process:this.addFile,
        load: this.loadFile,
        revert: null
      },
      openImageURL: "",
      myFiles: [],
      screenshot: {},
      extension: 'png',
      showOpenCamera: true,
    };
  },
  computed: {
    caseId() {
      return store.case_id;
    },
  },
  async mounted() {
    // on the case page we have different case id param name:
    this.case_id = this.caseId || this.$route.query.case_id || this.$route.query.caseid; // TODO
    this.token = this.$route.query.access_token;
    const file = this.fieldData.value
    
    if(file){
      this.myFiles.push({
        source:file, options:{type:"local"}
      })
    }
    this.config['label-idle'] = this.fieldData?.placeholder || '';
  },

  methods: {
    addCapture(payload) {
      const name = new Date().toISOString().replace('T', '_').replaceAll(':', '-').split('.')[0] + '.' + this.extension;
      const file = this.dataURLtoFile(payload, name);
      this.$refs.pond.addFile(file);
      this.toggleCamera();
    },
    async toggleCamera() {
      if (!this.isCameraOpen) {
        this.isCameraLoading = true;
        this.isCameraOpen = await this.$refs.cameraField.openCamera();
        this.isCameraLoading = false;
      } else {
        this.$refs.cameraField.toggleCamera();
        this.isCameraOpen = false;
      }
    },
    async updateFiles(files) {
      if (!files.length) {
        this.showOpenCamera = true;
        this.$emit('input', '');
      } else {
        this.showOpenCamera = false;
      }
    },
    async loadFile(source, load){

      try{
        let entityId = this.$route?.query?.caseEntity?.id || this.$route.query.entityId
        const response = await axios.get(`/case/form/${this.case_id}/entity/${entityId}/files/${source}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.token}`
          },
          responseType:"blob"
        })


        load(response.data)

      }catch(error){
        console.log('file fetching error', error)
      }



    },
    async addFile(fieldname, file, metadata, load, error, progress, abort) {
      const case_id = this.$route.query.caseid || this.$route.query.case_id;
      const token = this.$route.query.access_token;
      console.log(fieldname, metadata, error, abort)

      let formData = new FormData();

      formData.append('file', file);

      try {
        const response = await axios({
          url:`/case/form/${case_id}/entity/${this.$route.query.entityId}/upload-file`,
          method:"post",
          data:formData, 
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${token}`
          },
          onUploadProgess:(e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }
        })
        this.$emit('input', response.data.data.file_path);
        load(response.data.data.file_path)
      } catch (error) {
        console.log('file loading error', error);
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
          
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
  },
};
</script>

<style scoped lang="scss">
.add-photo {
  &__controls {
    transition: all .2s ease-in-out;
  }
}

.camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  display: flex;
  margin-top: 5px;
  width: 100%;
  
  ul {
    height: 100%;
    z-index: 999999;
    margin: 0;
  }
  
  .loader-circle {
    display: block;
    height: 14px;
    margin: 0 auto;
    padding: 0;
    
    li {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      line-height: 10px;
      padding: 0;
      position: relative;
      margin: 0 0 0 4px;
      background: #999;
      animation: preload 1s infinite;
      border-radius: 100%;
      
      &:nth-child(2) {
        animation-delay: .2s;
      }
      
      &:nth-child(3) {
        animation-delay: .4s;
      }
    }
  }
}
@keyframes preload {
  0% {
    opacity: 1
  }
  50% {
    opacity: .4
  }
  100% {
    opacity: 1
  }
}


::v-deep {
  .filepond {
    // &--root {
    // }
    &--drop-label {
      min-height: 10rem;
    }
    &--item {
      list-style: none;
    }
    &--file {
      min-height: 50px;
    }
  }
  .filepond--file-action-button {
    &.filepond--action-remove-item {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .filepond--download-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .filepond--file-info {
    margin-left: 5px;
  }
  .filepond--file-info-main {
    @apply text-sm;
  }
}
.camera-capture {
  &--empty {
    ::v-deep {
      .filepond {
        &--item {
          @apply ml-40;
        }
      }
      .add-photo {
        &__controls {
          position: absolute;
          transform: translateY(-50%);
          top: 39%;
        }
      }
    }
  }
}
.error__highlight {
    ::v-deep {
        .filepond--drop-label {
            border: 1px solid rgb(220, 38, 38);
            border-radius: 4px;
        }
        label {
            color: rgb(220, 38, 38);
        }
    }
}
.labelCamera{
  display: none; 
}
@media screen and (min-width: 443px) and (max-width: 951px) {
.camera-capture {
  &--empty {
    ::v-deep {
      .filepond {
        &--item {
          @apply ml-40;
        }
      }
      .add-photo {
        &__controls {
          position: absolute;
          transform: translateY(-50%);
          top: 80%;
          left: 41% !important;
          z-index: 9999;
        }
      }
        label {
          margin-top: -95px;
          color: black;
        }
    }
  } 
  .labelCamera{
      display: block; 
      position: relative !important;
      top: -28% !important;
      left: 56px !important;
      margin-top: -74% !important;
      margin-bottom: 11%;
      font-size: 14px;
      color: grey;
  }
  
}


}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .camera-capture {
  &--empty {
    ::v-deep {
      .filepond {
        &--item {
          @apply ml-40;
        }
      }
      .add-photo {
        &__controls {
          position: absolute;
          transform: translateY(-50%);
          top: 80%;
          left: 34%;
          z-index: 9;
        }
      }
        label {
          margin-top: -95px;
          color: black;
        }
    }
  } 
  .labelCamera{
      display: block; 
      position: relative !important;
      top: -28% !important;
      left: 56px !important;
      margin-top: -74%;
      margin-bottom: 11%;
      font-size: 14px;
      color: grey;
  }
}

}
</style>